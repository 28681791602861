import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { rdt } from './rdt';
import { RadixDappToolkit } from "@radixdlt/radix-dapp-toolkit";
// import { appsignal } from "~/lib/appsignal";

type Transaction = {
	error?: string | null;
	intentHash?: string | null
}

type RDC = {
   rdt: RadixDappToolkit | undefined;
   sendTransaction: Function;
   transactionState: 'idle' | 'submitting' | 'error';
   transaction: Transaction;
};

export const RadixContext = createContext<RDC>({
   rdt: rdt,
   sendTransaction: (manifest: string, message?: string) => {},
   transactionState: 'idle',
	transaction: {}
});

const RadixProvider = (input: React.PropsWithChildren) => {
	const [transactionState, setTransactionState] = useState<'idle' | 'submitting' | 'error'>('idle');
	const [transaction, setTransaction] = useState<Transaction | null>(null);

	const sendTransaction = useCallback(async (transactionManifest: string, message?: string) => {
		
		setTransactionState('submitting');
		setTransaction(null);

		console.log(transactionManifest);

		const result = await rdt?.walletApi.sendTransaction({
			transactionManifest,
			version: 1,
			message
		});

		if (result.isErr()) {
         console.error(result.error);
         setTransactionState('error');

         const message = result.error.message.replace('ET failed to generate TX review: Failed to retrive TX receipt from gateway: (', '');

			setTransaction({
				error: message
			});

			const error = new Error(result.error.message);
			// appsignal.sendError(error, (span) => {
			// 	span.setAction('Transaction');
			// 	span.setNamespace(result.error.error);
         // });

         return result;
      }

		console.log('result:', result);

		setTransactionState('idle');
		setTransaction({
         intentHash: result.value.transactionIntentHash
      });

		return result;
	}, []);

	const contextValue = useMemo(() => ({ rdt, sendTransaction, transactionState, transaction }), [ rdt, sendTransaction, transactionState, transaction ]);

	return <RadixContext.Provider value={contextValue}>{input.children}</RadixContext.Provider>;
};

export const useRadix = () => useContext(RadixContext);

export default RadixProvider;