import { RadixDappToolkit, Logger, DataRequestBuilder } from "@radixdlt/radix-dapp-toolkit";
import { DAPP_DEFINITION_ACCOUNT_ADDRESS, RADIX_NETWORK_ID, isBrowser } from "~/lib/util";

let rdtInstance;

if (isBrowser) {
  rdtInstance = RadixDappToolkit({
    dAppDefinitionAddress: DAPP_DEFINITION_ACCOUNT_ADDRESS,
    networkId: RADIX_NETWORK_ID,
    applicationName: 'HUG',
    applicationVersion: '1.0.0',

    logger: Logger(2),
  });

  rdtInstance.walletApi.setRequestData(DataRequestBuilder.accounts().atLeast(1));
}

export const rdt = rdtInstance;